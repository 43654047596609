import { Box, Grid } from "@mui/material"
import React, { useState } from "react"
import LayoutPage from "src/components/page-layout/layout-page"
import AlertMessage from "src/components/alert-message"

const RelatorioRaterPage = () => {
    const [loading] = useState(false)
    const [alertMessageState, setAlertMessageState] = React.useState({
        open: false,
        message: "",
        type: ""
    })


    const closeAlert = () => {
        setAlertMessageState({ ...alertMessageState, open: false })
    }

    return (
        <LayoutPage title="Calculadora Rater" loading={loading}>
            <AlertMessage open={alertMessageState.open} message={alertMessageState.message} type={alertMessageState.type} close={closeAlert} />
            <Box
                sx={{
                    borderBottom: 1,
                    borderColor: 'divider',
                    height: "100vh",
                    display: "flex",
                    flexDirection: "column"
                }}>


                <Box
                    sx={{
                        flex: 1,
                        display: "flex",
                        flexDirection: "column",
                    }}>
                    <Grid
                        container
                        direction="row"
                        alignContent="flex-start"
                        justifyContent="flex-start"
                        spacing={0}
                        sx={{
                            padding: 2,
                            height: "100%", // altura de 100% da Box
                        }}>
                        <Grid item xs={12} sm={12} md={12} xl={12} sx={{ height: "100%" }}>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </LayoutPage>
    )
}

export default RelatorioRaterPage