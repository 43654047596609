import React from "react"
import { Box, Typography } from "@mui/material"
import dayjs from "dayjs"
import { DATE_FORMAT_SHOW } from "src/utils/constants"

// Função para renderizar a célula com uma data formatada
const renderCellDate = (params) => {
    if (params.value) {
        return dayjs(params.value).format(DATE_FORMAT_SHOW)
    }
    return "" // Retorna string vazia caso o valor seja nulo/indefinido
}

// Função para renderizar uma coluna com texto multilinha
const renderTextColumn = (params) => (
    <Box sx={{ whiteSpace: "pre-line", height: "auto" }}>
        <Typography component="span">{params.value}</Typography>
    </Box>
)

// Função para calcular a largura mínima com base no conteúdo
const calculateMinWidth = (rows, field) => {
    const longestText = rows.reduce((longest, row) => {
        const text = row[field] ? row[field].toString() :  "" // Garante que o texto seja uma string
        return text.length > longest.length ? text : longest
    }, "")
    return Math.max(longestText.length * 5, 150) // Largura mínima de 150
}

// Exportação das funções
export { renderCellDate, renderTextColumn, calculateMinWidth }
