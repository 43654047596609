import { Button, Grid, TextField } from "@mui/material"
import dayjs from "dayjs"
import React, { useEffect, useState } from "react"
import ModalDialogPage from "src/components/modal-dialog"
import TableGray from "src/components/table/table-gray"
import EmissaoService from "src/services/emissao-service"
import { DATE_FORMAT_SHOW } from "src/utils/constants"
import PropTypes from 'prop-types'
import SkeletonLine from "src/components/skeleton-line"
import { ordenaPorDataReferencia } from "src/utils/date-format"
import ParameterTable from "src/components/table/parameter-table"
import { calculateMinWidth, renderCellDate, renderTextColumn } from "./utils/table-emissal-functions"

const defaultTable = [
    {
        cells: ["CETIP: ", "ISIN: "]
    }
]

const UltimoEventoCreditoEmissao = ({ admin, value, cetipIsin, loading }) => {
    const ultimoCreditoEmissaoDefault = { data_referencia: "", evento_credito: "", score_evento: "" }
    const [openMostrarTodos, setOpenMostrarTodos] = useState(false)
    const emissaoService = new EmissaoService()
    const [ultimoCreditoEmissao, setUltimoCreditoEmissao] = useState(ultimoCreditoEmissaoDefault)
    const [loadingEventoCreditoEmissao, setLoadingEventoCreditoEmissao] = useState(false)
    const [rowsEventoCreditoEmissao, setRowsEventoCreditoEmissao] = useState([])
    const [table, setTable] = useState(defaultTable)

    useEffect(() => {
        console.log('UltimoEventoCreditoEmissao: ', value)
        if (validateValue()) loadData(value)
    }, [value])

    const validateValue = () => {
        if (value && value.length > 4) {
            return true
        }

        return false
    }

    const loadData = (value) => {
        console.log('UltimoEventoCreditoEmissao: ', value)
        setLoadingEventoCreditoEmissao(true)
        emissaoService.getUltimoEventoCreditoEmissao(value).then((response) => {
            const { body } = response
            const { data } = body

            if (data !== null) {
                const { DataReferencia, EventoCreditoEmissao, ScoreEvento } = data

                setUltimoCreditoEmissao({
                    data_referencia: dayjs(DataReferencia).format(DATE_FORMAT_SHOW),
                    evento_credito: EventoCreditoEmissao,
                    score_evento: ScoreEvento,
                })
            } else {
                setUltimoCreditoEmissao(ultimoCreditoEmissaoDefault)
            }
        }).catch(() => {
            setUltimoCreditoEmissao(ultimoCreditoEmissaoDefault)
        }).finally(() => {
            setLoadingEventoCreditoEmissao(false)
        })
    }

    const buildTable = () => {
        setTable(
            [
                {
                    cells: [`CETIP: ${cetipIsin.cetip}`, `ISIN: ${cetipIsin.isin}`]
                },
            ]
        )
    }

    const openEventoCreditoEmissaoModal = () => {
        if (!validateValue()) {
            setOpenMostrarTodos(true)
            return
        }

        buildTable()
        loading(true)
        emissaoService.eventoCreditoEmissao("get", `?CETIP=${value}`)
            .then((response) => {
                setOpenMostrarTodos(true)
                const { body } = response
                const { results } = body
                const rows = ordenaPorDataReferencia(results, "desc").map((obj, index) => ({ ...obj, id: index }))
                setRowsEventoCreditoEmissao(rows)
            })
            .catch(() => { })
            .finally(() => {
                loading(false)
            })
    }

    const deleteItem = async (row) => {
        const { ID } = row

        loading(true)
        emissaoService.eventoCreditoEmissao("delete", ID)
            .then(() => { openEventoCreditoEmissaoModal() })
            .finally(() => {
                loading(false)
            })
    }

    const addRow = async () => {
        if (rowsEventoCreditoEmissao.length === 0) {
            return
        }
        const lastItem = rowsEventoCreditoEmissao[0]

        loading(true)
        emissaoService.eventoCreditoEmissao("post", lastItem)
            .then(() => {
                openEventoCreditoEmissaoModal()
            })
            .catch(() => { })
            .finally(() => {
                loading(false)
            })
    }

    const updateRow = async (newRow) => {
        const { ID } = newRow
        console.log(newRow)
        loading(true)
        emissaoService.eventoCreditoEmissao("patch", ID, newRow)
            .finally(() => {
                loading(false)
            })

        return newRow
    }

    const minWidthEvento = calculateMinWidth(rowsEventoCreditoEmissao, "EventoCreditoEmissao")

    return <>
        {loadingEventoCreditoEmissao ? <SkeletonLine /> :
            <Grid container direction="row" alignItems={"center"} justifyContent="space-between" spacing={2}>
                <Grid item xs={12} md={3} sm={12} xl={3}>
                    <TextField fullWidth label="Data Referência" value={ultimoCreditoEmissao.data_referencia} />
                </Grid>
                <Grid item xs={12} md={3} sm={12} xl={3}>
                    <TextField fullWidth label="Evento de Crédito" value={ultimoCreditoEmissao.evento_credito} />
                </Grid>
                <Grid item xs={12} md={3} sm={12} xl={3}>
                    <TextField fullWidth label="Score Evento" value={ultimoCreditoEmissao.score_evento} />
                </Grid>
                <Grid item xs={12} md={3} sm={12} xl={3}>
                    <Button fullWidth variant="contained" onClick={openEventoCreditoEmissaoModal}>Mostrar Todos</Button>
                </Grid>
            </Grid>
        }

        <ModalDialogPage open={openMostrarTodos}
            title="Último Evento de Crédito Emissão"
            close={() => {
                setOpenMostrarTodos(false)
            }}>
            <>
                <Grid container direction="column" spacing={2}>
                    <Grid item xs={12} md={12} xl={12}>
                        <TableGray data={table} />
                    </Grid>
                </Grid>
                <br />
                <Grid container direction="column">
                    <Grid item xs={12} md={12} xl={12}>
                        <ParameterTable
                            columns={[
                                { field: 'DataReferencia', headerName: 'Data Referencia', minWidth: 150, flex: 1, editable: admin, renderCell: renderCellDate },
                                { field: 'EventoCreditoEmissao', headerName: 'Evento', flex: 1, minWidth: minWidthEvento, editable: admin, renderCell: renderTextColumn  },
                                { field: 'ScoreEvento', headerName: 'Score Evento', editable: admin, flex: 1 },
                            ]}
                            data={rowsEventoCreditoEmissao}
                            setData={setRowsEventoCreditoEmissao}
                            createRow={addRow}
                            deleteRow={deleteItem}
                            updateRow={updateRow}
                            loadData={loadData}
                            disableDeleteColumn={!admin}
                            disableAddRow={!admin}
                            buttonAddVariant={"contained"}
                            buttonAddTitle={"Adicionar Rating"}
                        />

                    </Grid>
                </Grid>
            </>
        </ModalDialogPage>
    </>
}

UltimoEventoCreditoEmissao.propTypes = {
    admin: PropTypes.bool,
    cetipIsin: PropTypes.object,
    value: PropTypes.string,
    dadosEmpresa: PropTypes.object,
    loading: PropTypes.func,
}

export default UltimoEventoCreditoEmissao