import React, { useEffect, useState } from "react"
import { formatDateDataGrid } from "src/utils/format-date"
import PropTypes from 'prop-types'
import ParameterPaginationTable from "src/components/table/parameter-pagination-table"
import DatalakeService from "src/services/datalake-service"
import dayjs from "dayjs"
import { DATE_FORMAT } from "src/utils/constants"

const ParameterDatalakeEmissaoCaracteristicasTable = (props) => {
    const dataLakeService = new DatalakeService()
    const [data, setData] = useState([])
    const [pagination, setPagination] = useState({ count: 0, next: null, previous: null })

    useEffect(() => {
        if(props.filters && props.filters.length > 0) {
            onFilter(props.filters, 25, 0)
            return
        }
        
        loadData()
    }, [])

    const loadData = () => {
        props.loading(true)
        dataLakeService.loadPaginationEndpoint(props.endpoint).then((response) => {
            props.loading(false)

            const { body } = response
            const { results, count, next, previous } = body
            const list = results.map((obj, index) => ({ ...obj, id: index }))
            setPagination({ count: count, next: next, previous: previous })
            setData(list)
        })
    }

    const createRow = async () => {
        const body = {
            CETIP: 'N/A',
            VersaoDocumento: 0,
            DataDocumentacao: dayjs().format(DATE_FORMAT),
            TipoAmortizacao: null,
            CotaSubordinacao: null,
            ValorSerie: null,
            ValorTotalEmissao: null,
            AgenteFiduciario: null,
            CoordenadorLider: null,
            IFContaRecebedora: null,
            Securitizador: null,
            Custodiante: null,
            Cedente: null,
            Auditoria: null,
            AgenciaRating: null,
            Devedor: null,
            CNPJ_CPF_Devedor: null,
            ResumoDevedor: null,
            TipoLastro: null,
            Coobrigacao: null,
            ContaVinculada: null,
            AlienacaoFiduciaria: null,
            CessaoFiduciaria: null,
            FiancaBancaria: null,
            Hipoteca: null,
            Penhor: null,
            Avalista: null,
            Fianca: null,
            FundoObra: null,
            FundoReserva: null,
            FundoLiquidez: null,
            PrePagamento: null,
            Seguro: null,
            Sobregarantia: null,
            Covenants: null,
            RepactuacaoCredito: null
        }

        props.loading(true)
        return dataLakeService.createTableEndpoint(props.endpoint, body).then(() => {
            props.loading(false)
            loadData()
        }).catch((err) => {
            props.loading(false)
            throw err
        })
    }

    const updateRow = async (newRow) => {
        const { ID, CETIP, VersaoDocumento, DataDocumentacao, TipoAmortizacao, CotaSubordinacao, ValorSerie, ValorTotalEmissao,
            AgenteFiduciario, CoordenadorLider, IFContaRecebedora, Securitizador, Custodiante, Cedente, Auditoria, AgenciaRating,
            Devedor, CNPJ_CPF_Devedor, ResumoDevedor, TipoLastro, Coobrigacao, ContaVinculada, AlienacaoFiduciaria, CessaoFiduciaria,
            FiancaBancaria, Hipoteca, Penhor, Avalista, Fianca, FundoObra, FundoReserva, FundoLiquidez, PrePagamento, Seguro,
            Sobregarantia, Covenants, RepactuacaoCredito } = newRow

        const body = {
            CETIP: CETIP,
            VersaoDocumento: VersaoDocumento,
            DataDocumentacao: DataDocumentacao,
            TipoAmortizacao: TipoAmortizacao,
            CotaSubordinacao: CotaSubordinacao,
            ValorSerie: ValorSerie,
            ValorTotalEmissao: ValorTotalEmissao,
            AgenteFiduciario: AgenteFiduciario,
            CoordenadorLider: CoordenadorLider,
            IFContaRecebedora: IFContaRecebedora,
            Securitizador: Securitizador,
            Custodiante: Custodiante,
            Cedente: Cedente,
            Auditoria: Auditoria,
            AgenciaRating: AgenciaRating,
            Devedor: Devedor,
            CNPJ_CPF_Devedor: CNPJ_CPF_Devedor,
            ResumoDevedor: ResumoDevedor,
            TipoLastro: TipoLastro,
            Coobrigacao: Coobrigacao,
            ContaVinculada: ContaVinculada,
            AlienacaoFiduciaria: AlienacaoFiduciaria,
            CessaoFiduciaria: CessaoFiduciaria,
            FiancaBancaria: FiancaBancaria,
            Hipoteca: Hipoteca,
            Penhor: Penhor,
            Avalista: Avalista,
            Fianca: Fianca,
            FundoObra: FundoObra,
            FundoReserva: FundoReserva,
            FundoLiquidez: FundoLiquidez,
            PrePagamento: PrePagamento,
            Seguro: Seguro,
            Sobregarantia: Sobregarantia,
            Covenants: Covenants,
            RepactuacaoCredito: RepactuacaoCredito
        }

        props.loading(true)
        return dataLakeService.updateTableEndpoint(props.endpoint, ID, body).then(() => {
            props.loading(false)
        }).catch((err) => {
            props.loading(false)
            throw err
        })
    }

    const deleteRow = async (row) => {
        props.loading(true)
        const { ID } = row
        return dataLakeService.deleteTableEndpoint(props.endpoint, ID).then(() => {
            props.loading(false)
            const listRemoved = data.filter((item) => item.ID !== ID)
            setData(listRemoved)
        }).catch((err) => {
            props.loading(false)
            throw err
        })
    }

    const onFilter = async (filterList, limit, offset) => {
        const query = filterList.map(obj => `${encodeURIComponent(obj.field)}=${encodeURIComponent(obj.value)}`).join('&')

        props.loading(true)
        dataLakeService.loadPaginationEndpoint(props.endpoint, query, limit, offset).then((response) => {
            props.loading(false)
            const { body } = response
            const { results, count, next, previous } = body
            const list = results.map((obj) => ({ ...obj, id: obj.ID }))
            setPagination({ count: count, next: next, previous: previous })
            setData(list)
        })
    }

    const changePage = async (endpoint) => {
        props.loading(true)
        dataLakeService.paginate(endpoint).then((response) => {
            props.loading(false)
            const { body } = response
            const { results, count, previous, next } = body
            const list = results.map((obj) => ({ ...obj, id: obj.ID }))

            setPagination({ count: count, next: next, previous: previous })
            setData([])
            setData(list)
        })
    }

    const columns = [
        {
            field: 'ID',
            headerName: '#',
            disableColumnMenu: true,
            disableFilter: true,
            flex: true,
            minWidth: 80,
        },
        {
            field: 'CETIP',
            headerName: 'CETIP',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'VersaoDocumento',
            headerName: 'VersaoDocumento',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            typeComponent: 'number',
            type: 'number',
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'DataDocumentacao',
            headerName: 'DataDocumentacao',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            typeComponent: 'date',
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'TipoAmortizacao',
            headerName: 'TipoAmortizacao',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'CotaSubordinacao',
            headerName: 'CotaSubordinacao',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'ValorSerie',
            headerName: 'ValorSerie',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            typeComponent: 'number',
            type: 'number',
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'ValorTotalEmissao',
            headerName: 'ValorTotalEmissao',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            typeComponent: 'number',
            type: 'number',
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'AgenteFiduciario',
            headerName: 'AgenteFiduciario',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'CoordenadorLider',
            headerName: 'CoordenadorLider',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'IFContaRecebedora',
            headerName: 'IFContaRecebedora',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'Securitizador',
            headerName: 'Securitizador',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'Custodiante',
            headerName: 'Custodiante',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'Cedente',
            headerName: 'Cedente',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'Auditoria',
            headerName: 'Auditoria',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'AgenciaRating',
            headerName: 'AgenciaRating',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'Devedor',
            headerName: 'Devedor',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'CNPJ_CPF_Devedor',
            headerName: 'CNPJ_CPF_Devedor',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'ResumoDevedor',
            headerName: 'ResumoDevedor',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'TipoLastro',
            headerName: 'TipoLastro',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'Coobrigacao',
            headerName: 'Coobrigacao',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'ContaVinculada',
            headerName: 'ContaVinculada',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'AlienacaoFiduciaria',
            headerName: 'AlienacaoFiduciaria',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'CessaoFiduciaria',
            headerName: 'CessaoFiduciaria',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'FiancaBancaria',
            headerName: 'FiancaBancaria',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'Hipoteca',
            headerName: 'Hipoteca',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'Penhor',
            headerName: 'Penhor',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'Avalista',
            headerName: 'Avalista',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'Fianca',
            headerName: 'Fianca',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'FundoObra',
            headerName: 'FundoObra',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'FundoReserva',
            headerName: 'FundoReserva',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'FundoLiquidez',
            headerName: 'FundoLiquidez',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'PrePagamento',
            headerName: 'PrePagamento',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'Seguro',
            headerName: 'Seguro',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'Sobregarantia',
            headerName: 'Sobregarantia',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'Covenants',
            headerName: 'Covenants',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'RepactuacaoCredito',
            headerName: 'RepactuacaoCredito',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'CriadoEm',
            headerName: 'Data de Criação',
            flex: true,
            minWidth: 150,
            disableColumnMenu: true,
            disableFilter: true,
            renderCell: (params) => formatDateDataGrid(params)
        },
        {
            field: 'AtualizadoEm',
            headerName: 'Data de Atualização',
            flex: true,
            minWidth: 150,
            disableColumnMenu: true,
            disableFilter: true,
            renderCell: (params) => formatDateDataGrid(params)
        }
    ]

    return (
        <div>
            <ParameterPaginationTable
                columns={columns}
                data={data}
                disableToolbar={props.disableToolbar ? props.disableToolbar : false}
                setData={setData}
                createRow={createRow}
                deleteRow={deleteRow}
                updateRow={updateRow}
                filterFunction={onFilter}
                pagination={pagination}
                onChangePage={changePage}
                customFilter={true} />
        </div>
    )
}

ParameterDatalakeEmissaoCaracteristicasTable.propTypes = {
    loading: PropTypes.func,
    endpoint: PropTypes.string,
    filters: PropTypes.array,
    disableToolbar: PropTypes.bool
}

export default ParameterDatalakeEmissaoCaracteristicasTable
