import { Autocomplete, Box, Button, CircularProgress, Grid, Tab, Tabs, TextField } from "@mui/material"
import React, { useState } from "react"
import ModalDialogPage from "src/components/modal-dialog"
import LayoutPage from "src/components/page-layout/layout-page"
import EmissaoTab from "./tab/emissao_tab"
import FluxoEventosTab from "./tab/fluxo_eventos_tab"
import DocumentosTab from "./tab/documentos_tab"
import { DataGrid } from "@mui/x-data-grid"
import EmissaoService from "src/services/emissao-service"
import { TokenService } from "src/services/token-service"


const EmissaoPage = () => {
    const emissaoService = new EmissaoService()
    const tokenService = new TokenService()

    const [selectedTab, setSelectedTab] = useState(0)
    const [autoCompleteOptions, setAutoCompleteOptions] = useState([])
    const [autoCompleteLoading, setAutoCompleteLoading] = useState(false)
    const [loading, setLoading] = useState(false)
    const [openModalError, setOpenModalError] = useState(false)
    const [modalCompleteList, setModalCompleteList] = useState(false)
    const [reportErrorMessage, setReportErrorMessage] = useState("")
    const [cetipIsin, setCetipIsin] = useState({ cetip: "", isin: "" })
    const [clickedValue, setClickedValue] = useState("")
    const [cetipIsinInput, setCetipIsinInput] = useState("")
    const [cetipEmissaoList, setCetipEmissaoList] = useState([])
    const [filterData, setFilterData] = useState([])
    const [userAdmin, setUserAdmin] = useState(false)

    useState(() => {
        const userIsAdmin = tokenService.verifyUserIsAdmin()
        setUserAdmin(userIsAdmin)
    }, [])


    const sendReportError = () => {
    }

    const openModalCompleteList = () => {
        setLoading(true)
        emissaoService.listCetipIsinEmissao("")
            .then((response) => {
                const { body } = response
                const { data } = body

                const rows = data.map((obj, index) => ({ ...obj, id: index }))

                setCetipEmissaoList(rows)
                setModalCompleteList(true)
            })
            .catch(() => {
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const renderContent = () => {
        switch (selectedTab) {
            case 0: return <EmissaoTab admin={userAdmin} cetipIsin={cetipIsin} value={clickedValue} />
            case 1: return <FluxoEventosTab />
            case 2: return <DocumentosTab />
            default: null
        }
    }

    const filterByValue = (data, value) => {
        // Criando uma expressão regular para busca, ignorando maiúsculas/minúsculas
        const regex = new RegExp(value, 'i')

        // Inicializando arrays para armazenar os resultados
        const cetipArray = []
        const isinArray = []

        // Iterando uma única vez pelos itens para buscar em ambos os campos
        data.forEach(item => {
            if (regex.test(item.CETIP)) {
                cetipArray.push(item.CETIP)
            }
            if (regex.test(item.ISIN)) {
                isinArray.push(item.ISIN)
            }
        })

        return { cetipArray, isinArray }
    }


    const cetipIsinList = (value) => {
        if (value.length < 4) {
            return
        }

        const filterNewOpetions = autoCompleteOptions.filter((item) => item.includes(value))
        if (filterNewOpetions.length > 0) {
            return
        }

        setClickedValue(value)
        setAutoCompleteLoading(true)
        emissaoService.listCetipIsinEmissao(value)
            .then((response) => {
                const { body } = response
                const { data } = body

                setFilterData(data)
                const { cetipArray, isinArray } = filterByValue(data, value)
                const newOptions = Array.from(new Set([...cetipArray, ...isinArray]))

                setAutoCompleteOptions(newOptions)

            })
            .catch(() => { })
            .finally(() => { setAutoCompleteLoading(false) })
    }

    const handleClickMostrarTodosTable = (data) => {
        const { row } = data
        const { CETIP, ISIN } = row
        setClickedValue(ISIN)

        if (CETIP !== null || CETIP !== undefined) {
            setClickedValue(CETIP)
        }

        setModalCompleteList(false)
    }

    const changeCetipIsin = (value) => {
        selectedIsin(value)
        setCetipIsinInput(value)
        setClickedValue(value)
    }

    const selectedIsin = (option) => {
        const data = filterData.filter(item => item.CETIP === option || item.ISIN === option)

        if (data.length > 0 && data[0]) {
            const { CETIP, ISIN } = data[0]
            setCetipIsin({ cetip: CETIP, isin: ISIN })
        }
    }

    return <LayoutPage title="Emissão" loading={loading}>

        <Grid direaction={"row"} container alignItems="center" justifyContent="space-between" spacing={3}>
            <Grid item xs={6} md={8} sm={12}>
                <Grid container direction={"row"} spacing={3} alignItems="center">
                    <Grid item xs={4} md={6} sm={6}>
                        <Autocomplete
                            disablePortal
                            inputValue={cetipIsinInput}
                            value={clickedValue.toUpperCase()}
                            loading={autoCompleteLoading}
                            options={autoCompleteOptions}
                            onInputChange={(event, newValue) => { changeCetipIsin(newValue) }}
                            fullWidth
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="CETIP ou ISIN"
                                    onChange={(event) => cetipIsinList(event.target.value)}
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <React.Fragment>
                                                {autoCompleteLoading ? (
                                                    <CircularProgress color="primary" size={20} />
                                                ) : null}
                                                {params.InputProps.endAdornment}
                                            </React.Fragment>
                                        ),
                                    }}
                                />
                            )}
                            renderOption={(props, option) => (
                                <li {...props} >
                                    {option}
                                </li>
                            )}
                            noOptionsText={autoCompleteLoading ? "Carregando..." : "Nenhum resultado encontrado"}
                            loadingText="Carregando opções..."
                        />
                    </Grid>
                    <Grid item xs={6} md={6} sm={6}>
                        <Button color={"primary"} variant={"contained"} onClick={openModalCompleteList}>Consultar Lista Completa</Button>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <Button color={"primary"} variant={"contained"} onClick={() => { setReportErrorMessage(null), setOpenModalError(true) }}>Reportar Erro</Button>
            </Grid>
        </Grid>

        <br />

        <Grid container direction={"row"} alignItems={"center"}>
            <Grid item xs={12} sm={12} md={12} xl={12} sx={{ height: "100%" }}>
                <Box
                    sx={{
                        height: "100vh",
                        display: "flex",
                        flexDirection: "column"
                    }}>
                    <Tabs value={selectedTab} onChange={(_, newValue) => setSelectedTab(newValue)} aria-label="tabs">
                        <Tab label={"EMISSÃO"} key={0} />
                        <Tab label={"FLUXO DE EVENTOS"} key={1} />
                        <Tab label={"DOCUMENTOS"} key={2} />
                    </Tabs>

                    <Box
                        sx={{
                            flex: 1,
                            display: "flex",
                            flexDirection: "column",
                        }}>
                        <Grid
                            container
                            direction="row"
                            alignContent="flex-start"
                            justifyContent="flex-start"
                            spacing={0}
                            sx={{
                                padding: 2,
                                height: "100%", // altura de 100% da Box
                            }}>
                            <Grid item xs={12} sm={12} md={12} xl={12} sx={{ height: "100%" }}>
                                {renderContent()}
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Grid>
        </Grid>

        {/* Modais */}
        <ModalDialogPage open={openModalError}
            title="Reportar Erro"
            close={() => {
                setOpenModalError(false)
            }}>
            <>
                <Grid container direction="column">
                    <Grid item xs={12} md={12} xl={12}>
                        <TextField
                            label="Mensagem"
                            variant="outlined"
                            placeholder="Descreva o erro ou inconsistência nas informações que encontrou que nosso time de suporte irá rever!"
                            multiline
                            focused
                            minRows={10}
                            value={reportErrorMessage}
                            onChange={(event) => setReportErrorMessage(event.target.value)}
                            fullWidth
                        />
                    </Grid>
                </Grid>
                <br />
                <Grid container direction="column" alignContent={"flex-end"} justifyContent={"flex-end"}>
                    <Grid item>
                        <Button color={"primary"} variant={"contained"} onClick={sendReportError}>Reportar Erro</Button>
                    </Grid>
                </Grid>
            </>
        </ModalDialogPage>

        <ModalDialogPage open={modalCompleteList}
            title="Lista de CETIP/ISIN"
            close={() => {
                setModalCompleteList(false)
            }}>
            <>
                <Grid container direction="column">
                    <Grid item xs={12} md={12} xl={12}>
                        <DataGrid
                            autoHeight
                            columns={[
                                { field: 'CETIP', headerName: 'CETIP', width: 200 },
                                { field: 'ISIN', headerName: 'ISIN', width: 200 },
                                { field: 'TipoAtivo', headerName: 'Tipo Ativo', width: 300 },
                                { field: 'Devedor', headerName: 'Devedor', width: 300 },
                            ]}
                            rows={cetipEmissaoList}
                            onRowClick={handleClickMostrarTodosTable}
                        />
                    </Grid>
                </Grid>
            </>
        </ModalDialogPage>

    </LayoutPage>
}

export default EmissaoPage